// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react';

class App extends Component {
  render = () => (
    <div className="App">
      <header className="App-header">
          Hello world<br/>
      </header>
    </div>
  )
}

export default App;
